import {
  from,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { Auth } from 'aws-amplify';
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { GetServerSidePropsResult, GetStaticPropsResult } from 'next';

import constants from '@/utils/constants';

import { isSsrMode } from './ssr';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CacheShape = any;

let apolloClient: ApolloClient<CacheShape>;

const region = 'ap-northeast-1';

// Apollo Clientをインスタンス化する
// GraphQLサーバーとしてAWS AppSyncを使うためのLinkの設定も行う
const createApolloClient = () => {
  const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
      try {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
      } catch (error) {
        console.info('Session Expired', error);
        // セッションが切れていたら明示的にログアウト処理を呼ぶ
        // これによりHubでAuthのイベントを購読している箇所が起動し、ログインフォームへのリダイレクトが行われる
        Auth.signOut();
        window.location.href = '/login';
        return '';
      }
    },
  };

  const timeoutLink = new ApolloLinkTimeout(30000);

  const httpLink = createHttpLink({
    uri: constants.apiEndpoint,
    credentials: 'same-origin',
  });

  const link = from([
    createAuthLink({ url: constants.apiEndpoint, region, auth }),
    createSubscriptionHandshakeLink(
      { url: constants.apiEndpoint, region, auth },
      timeoutLink.concat(httpLink),
    ),
  ]);

  return new ApolloClient({
    link,
    ssrMode: isSsrMode(),
    cache: new InMemoryCache(),
  });
};

// Apollo Clientの初期化済みのインスタンスがあればそれを取得し、無ければインスタンス化する
// 引数でキャッシュの初期状態が指定された場合、それをApollo Clientに適用する
export const initializeApollo = (initialState: CacheShape | null = null) => {
  // SSGとSSRの場合、変数apolloClientは常にundefinedなので、毎回新しいインスタンスが生成される
  const client = apolloClient ?? createApolloClient();

  // 初期状態が渡されていればキャッシュにマージ
  if (initialState) {
    const existingCache = client.extract();

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    client.cache.restore(data);
  }

  // SSGとSSRの場合はApollo Clientのインスタンスを変数に保存しない
  if (isSsrMode()) {
    return client;
  }

  if (!apolloClient) {
    apolloClient = client;
  }

  return client;
};

// サーバーサイド（GetServerSidePropsResult）のキャッシュをpropsを経由してクライアントサイドに渡すためのヘルパー関数
// Next.jsの GetStaticProps のPropsもanyなので引数の型もそれに合わせている
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addApolloStateStatic = <T extends { [key: string]: any }>(
  client: ApolloClient<CacheShape>,
  result: GetStaticPropsResult<T>,
) => {
  if ('props' in result) {
    Object.assign(result.props, {
      [APOLLO_STATE_PROP_NAME]: client.extract(),
    });
  }

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addApolloStateServerSide = <T extends { [key: string]: any }>(
  client: ApolloClient<CacheShape>,
  result: GetServerSidePropsResult<T>,
) => {
  if ('props' in result) {
    Object.assign(result.props, {
      [APOLLO_STATE_PROP_NAME]: client.extract(),
    });
  }

  return result;
};

// クライアントサイドでApollo Clientのインスタンスにアクセスするためのカスタムフック
// サーバーサイド（GetServerSidePropsResult）で作られたキャッシュデータの読み込みも行う
// Next.jsの _app.tsx のPropsもanyなので引数の型もそれに合わせている
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useApollo = (pageProps: any) => {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  return initializeApollo(state);
};
