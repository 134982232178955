import type { Constants } from './index';

const config: Constants = {
  isProduction: false,
  pinpointAppId: '21b6a6b22bd148d4a10dc7ed6f273ec3',
  identityPoolId: 'ap-northeast-1:d35bf061-92ce-4acb-abc4-ebc06ec627f9',
  webAppFQDN: 'dev.e-gift.anygift.jp',
  adminWebAppFQDN: 'dev.admin.anygift.jp',
  apiEndpoint:
    'https://4r76mhsgfrg6zbugb4qs5u4g5i.appsync-api.ap-northeast-1.amazonaws.com/graphql',
  platformApiEndpoint:
    'https://op1wpvw8rl.execute-api.ap-northeast-1.amazonaws.com',
  stripePublicKey:
    'pk_test_51MU255A1SC8Bzvcw78Llu0Tpg5bEJJ7TEXXk52SWfuscF7VVhUx5rYNPnlUZgciUFQ0HTRDlwJ38xa9EtYaa8XlR00r9kNq0aK',
  stripeTestPublicKey:
    'pk_test_51MU255A1SC8Bzvcw78Llu0Tpg5bEJJ7TEXXk52SWfuscF7VVhUx5rYNPnlUZgciUFQ0HTRDlwJ38xa9EtYaa8XlR00r9kNq0aK',
  adminUserPoolId: '',
  adminUserPoolClientId: '',
  assetsDistributionUrl: 'https://d9ymj8bvr4wpk.cloudfront.net',
  shopifyAppId: '6699377',
  ebisumartAppsCode: 'ANYREACH-DEV',
  ebisumartAuthHeader:
    'QU5ZUkVBQ0gtREVWOjBjZGYyZGMwMjI5ZThlYjUyYmNlOGZmZGZiNWFjZDNjC',
};

export default config;
