import { pickBy } from 'lodash';

import devConfig from './dev';
import proConfig from './pro';

export type Constants = {
  isProduction: boolean;
  identityPoolId: string;
  pinpointAppId: string;
  webAppFQDN: string;
  adminWebAppFQDN: string;
  apiEndpoint: string;
  platformApiEndpoint: string;
  stripePublicKey: string;
  stripeTestPublicKey: string;
  adminUserPoolId: string;
  adminUserPoolClientId: string;
  assetsDistributionUrl: string;
  shopifyAppId: string;
  ebisumartAppsCode: string;
  ebisumartAuthHeader: string;
};

const getConstants = (
  isProduction: boolean,
  overrides: Partial<Constants> = {},
): Constants => {
  const constants = isProduction ? proConfig : devConfig;

  // undefinedなフィールドは取り除く
  overrides = pickBy(overrides, value => value !== undefined);

  return {
    ...constants,
    ...overrides,
  };
};

export default getConstants;
