import getConstants, { Constants } from '@common/constants';

const isProduction = !!process.env.IS_PRODUCTION;

const constants: Constants = getConstants(isProduction, {
  apiEndpoint: process.env.GRAPHQL_API_ENDPOINT,
  adminUserPoolId: process.env.ADMIN_USER_POOL_ID,
  adminUserPoolClientId: process.env.ADMIN_USER_POOL_CLIENT_ID,
});

export default constants;
