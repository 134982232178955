import { translations } from '@aws-amplify/ui';

export const authenticatorJaDict = {
  ...translations,
  ja: {
    ...translations.ja,
    // @see https://github.com/aws-amplify/amplify-ui/blob/main/packages/ui/src/i18n/dictionaries/authenticator/ja.ts
    Username: 'メールアドレス',
    'Forgot your password?': 'パスワードをお忘れですか？',
    'Sign in': 'ログインする ',
    'Change Password': 'パスワードを変更する',
    'Back to Sign In': 'ログインに戻る',
    'Signing in': 'ログイン中',
    // 以下は、サーバー側のメッセージ
    // https://qiita.com/ssugimoto/items/d0bc7540493499b6b154 から拝借して一部修正
    'User does not exist.': 'ユーザーが存在しません。',
    'Incorrect username or password.': 'ユーザー名またはパスワードが違います。',
    'User is not confirmed.': 'ユーザーは検証されていません。',
    'User already exists': 'ユーザーは既に存在します。',
    'Invalid verification code provided, please try again.':
      '指定された確認コードが無効です。もう一度お試しください。',
    'Invalid password format': 'パスワードのフォーマットが不正です。',
    'Account recovery requires verified contact information':
      'アカウントの復旧には確認済みの連絡先情報が必要です',
    'Invalid phone number format':
      '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
    'An account with the given email already exists.':
      'そのメールアドレスは既に存在します',
    'Username cannot be empty': 'ユーザー名は必須です。',
    'Password attempts exceeded': 'ログイン試行回数が上限に達しました。',
    'Attempt limit exceeded, please try after some time.':
      '試行制限を超過しました。しばらくしてからもう一度お試しください。',
    'Username/client id combination not found.': 'ユーザーが存在しません。',
    'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です。', // 本来の意味とは異なるが、パスワード未入力時に発生するのでこの訳としている
    'Confirmation code cannot be empty': 'コードは必須です。',
    'Password does not conform to policy: Password not long enough':
      'パスワードは8文字以上を入力してください。',
    'Password does not conform to policy: Password must have uppercase characters':
      'パスワードには大文字を含めてください。',
    'Password does not conform to policy: Password must have lowercase characters':
      'パスワードには小文字を含めてください。',
    'Password does not conform to policy: Password must have numeric characters':
      'パスワードには数字を含めてください。',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      'パスワードは8文字以上、大文字小文字を含む英数字と記号を含めてください。', // 適宜修正。本来の意味とは異なるがこれで明示している。
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
      'パスワードは8文字以上、大文字小文字を含む英数字と記号を含めてください。', // 適宜修正。本来の意味とは異なるがこれで明示している。
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[S]+.*[S]+$":
      'パスワードは8文字以上、大文字小文字を含む英数字と記号を含めてください。', // 適宜修正。本来の意味とは異なるがこれで明示している。
    'Temporary password has expired and must be reset by an administrator.':
      '一時パスワードは無効です。管理者によるリセットが必要です。',
    "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null":
      '入力チェックエラー、必須項目がNULLです。', //アカウント復旧でのメールアドレスのラジオをチェックONにせず、送信した場合
    'Invalid code received for user': '無効なコードです。', // TOTPでのトークンに誤りがある
    'Invalid session for the user, session is expired.':
      '無効なセッション、セッションは有効期限切れです。ログインからやり直してください', // ログインセッション無効です、ログインからやり直し
  },
};
