import '@/styles.css';
import 'tailwindcss/tailwind.css';

import { ApolloProvider } from '@apollo/client';
import { Authenticator } from '@aws-amplify/ui-react';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/nextjs';
import { Amplify, I18n } from 'aws-amplify';

import { BaseLayout } from '@/components/BaseLayout';
import { useApollo } from '@/utils/apollo';
import theme from '@/utils/chakra-theme';
import constants from '@/utils/constants';
import { authenticatorJaDict } from '@/utils/i18n';

import type { AppProps } from 'next/app';

I18n.putVocabularies(authenticatorJaDict);
I18n.setLanguage('ja');

Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: constants.adminUserPoolId,
    userPoolWebClientId: constants.adminUserPoolClientId,
    ssr: true,
  },
});

Sentry.init({
  normalizeDepth: 5,
  tracesSampleRate: 0,
  environment: process.env.ENV_NAME,
  dsn: 'https://a2a298171118439bbee5feecd28aca55@o1136612.ingest.sentry.io/4504774924959744',
});

export default function App({ Component, pageProps }: AppProps) {
  const client = useApollo(pageProps);

  return (
    <Authenticator.Provider>
      <ApolloProvider client={client}>
        <ChakraProvider resetCSS={false} theme={theme}>
          <BaseLayout>
            <Component {...pageProps} />
          </BaseLayout>
        </ChakraProvider>
      </ApolloProvider>
    </Authenticator.Provider>
  );
}
